import { QuestionCircleOutlined } from '@ant-design/icons';
import { Card, FloatButton, Steps, InputNumber, Space } from 'antd';
import { useEffect, useRef, useState } from 'react';
import './App.css';

function App() {
  const [count, setCount] = useState(0);
  const [progress, setProgress] = useState(0);
  const [gesamt, setGesamt] = useState(0);
  const kittenGame = useRef();
  var colors = ["red", "orange", "yellow", "green", "blue", "purple"];

  const changeGesamtValue = ((value) => {
      if (value && value > 0) {
        if (progress === 0) {
          setProgress(1);
          setGesamt(value);
        }
      }
      else
        setProgress(0);
  })

  const changeColors = () => {
    var style = kittenGame.current.style;
            style.right = String(Math.max(30, Math.random() * window.innerWidth - 30)) + "px";
            style.top = String(Math.max(30, Math.random() * window.innerHeight - 30)) + "px";
            setCount(count + 1);
    console.log(gesamt)
  }

  useEffect(() => {
    document.body.style.backgroundColor = colors[(colors.length - 1) - (count % colors.length - 1)];
  })

  return (
    <div>
      {count >= 42 &&
        <Card style={{ display: 'flex', justifyContent: 'center', border: 0, color: colors[Math.floor(Math.random() * colors.length)], margin: 20 }}>
          <h1 style={{fontSize: (42 + count/10), margin: 0}}>MOIN</h1>
        </Card>}
      <Card style={{maxWidth: '100%', margin: 20}}>
        <Steps current={progress} items={[
          { title: 'Gesamt Moneten' },
          { title: 'Ausgaben bisher' },
          { title: progress < 2 ? 'Da geht noch was' : 'Pleite :)' }
        ]}
        />
        <Space direction='vertical' size='middle' style={{ margin: 20, justifyContent: 'center', alignContent: 'center', width: '100%' }}>
          <InputNumber prefix="€" onChange={changeGesamtValue} />
          {progress >= 1 && <>
            <Card style={{ borderWidth: 4, borderColor: colors[Math.floor(Math.random() * colors.length)]}}>
              <InputNumber prefix="€" onChange={(value) => { (value && value > 0) ? (progress === 1 && setProgress(2)) : setProgress(1); }} />
            </Card>
          </>}
          {progress >= 2 && <>
            <InputNumber prefix="€" onChange={(value) => { (value && value > 0) ? (progress === 2 && setProgress(3)) : setProgress(2); }} />
          </>}
        </Space>
      </Card>
        <FloatButton ref={kittenGame} icon={<QuestionCircleOutlined />} badge={{count: count, color: colors[(count+1)%colors.length], overflowCount:999}}
          onMouseEnter={changeColors} onClick={changeColors}
        />
    </div>

  );
}

export default App;
